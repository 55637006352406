<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle">
      <template slot="button" v-if="!isCompany">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('TeacherCourses.Create')"
          placement="top"
        >
          <router-link :to="CreateLink">
            <el-button size="small" type="success">
              <i class="fa fa-plus"></i>
            </el-button>
          </router-link>
        </el-tooltip>
      </template>
    </Heading>
    <small v-if="$route.query.type === 'practice'">
      "Modules" are sets of complete English or Math sections. Each test
      consists of 2 English modules and 2 Math modules. An English module
      contains 27 questions, while a Math module contains 22 questions.
    </small>
    <small v-if="$route.query.type === 'drill'">
      "Drills" are sets of English or Math questions. They can contain any
      number of questions, though a drill typically includes 10 questions.
    </small>
    <hr />
    <el-form
      v-if="searchTest.isNew > -1 && searchTest.type"
      class="mt-4"
      :model="searchTest"
      label-position="right"
      label-width="80px"
      @submit.native.prevent
    >
      <template v-if="isAdmin">
        <!-- <el-form-item label="Source">
          <el-select
            :placeholder="$t('AddQuestions.Choose drill source')"
            style="width:100%"
            filterable
          >
            <el-option
              :label="getProfile.company.name"
              :value="getProfile.company.id"
            ></el-option>
            <el-option label="SATMocks" :value="1"></el-option>
            <el-option label="Official" :value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="Source">
          <div class="d-flex">
            <div>
              <el-radio-group v-model="searchTest.company_id" @change="search">
                <el-radio-button :label="null" :value="null">
                  SATMocks/Official
                </el-radio-button>
                <!-- <el-radio-button label="1" value="1">
                  SATMocks
                </el-radio-button>
                <el-radio-button label="2" value="2">
                  Official
                </el-radio-button> -->
                <!-- <el-radio-button :label="2" v-if="isAdmin">
                Company
              </el-radio-button> -->
              </el-radio-group>
            </div>
            <!-- <span class="ml-2 mr-2">
              <el-divider direction="vertical"></el-divider>
              Companies:
            </span> -->
            <div style="flex:1">
              <el-select v-model="searchTest.company_id" @change="search">
                <el-option
                  :key="company.id"
                  v-for="company in companies"
                  :label="company.name"
                  :value="company.id"
                >
                  <i class="el-icon-s-management" />
                  {{ company.name }}
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Version" prop="isNew">
          <el-radio-group
            v-model="searchTest.isNew"
            @change="search('changeNew')"
          >
            <el-radio-button :label="1">
              New Digital SAT
            </el-radio-button>
            <el-radio-button :label="0">
              Old Paper-Based SAT
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Type" prop="type" v-if="false">
          <el-radio-group v-model="searchTest.type" @change="search">
            <el-radio-button label="composePractices">
              {{ $t(`sat.Full Tests`) }}
            </el-radio-button>
            <el-radio-button label="practice">
              {{ $t(`sat.Partial Tests`) }}
            </el-radio-button>
            <el-radio-button label="drill">
              {{ $t(`sat.Drill Practice`) }}
            </el-radio-button>
            <!-- <el-radio-button label="adaptive">
              {{ $t("sat.Adaptive Tests") }}
            </el-radio-button> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="searchTest.type === 'practice' || searchTest.type === 'drill'"
          label="Subject"
        >
          <el-radio-group v-model="searchTest.category" @change="search">
            <!-- <el-radio-button :label="null">
              All
            </el-radio-button> -->
            <el-radio-button
              v-for="subject in subjectsNow"
              :key="subject.id"
              :label="subject.id"
            >
              {{ titleCase(subject.name) }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Status" prop="type">
          <div slot="label">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content" style="max-width: 350px;">
                <ul>
                  <li class="mb-1">
                    <strong>Public</strong>: Display this practice test
                    publicly, so <strong>all</strong> students on the website
                    can see it and do it.
                  </li>
                  <li class="mb-1">
                    <strong>Unlisted</strong>: This practice test is not shown
                    publicly, but anyone with its link can do it (Note: If
                    students without a Pro subscription and are not in your
                    class, they need to spend Coins to do it. If students
                    without a Pro subscription is in your class, they will spend
                    your Coins. If students have a Pro subscription, no Coins
                    will be deducted for doing this test).
                  </li>
                  <li>
                    <strong>Draft</strong>: These are work in progress, so
                    students cannot access it even with its link.
                  </li>
                </ul>
              </div>
              <el-button type="text"
                >Status <i class="fas fa-question-circle"
              /></el-button>
            </el-tooltip>
          </div>
          <el-radio-group v-model="searchTest.status" @change="search">
            <el-radio-button label="PUBLIC">
              Public
            </el-radio-button>
            <el-radio-button label="PRIVATE">
              Unlisted
            </el-radio-button>
            <el-radio-button label="DRAFT">
              Draft
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </template>
      <template v-else-if="isTeacher || isCompany">
        <el-form-item label="Source">
          <!-- <el-select
            :placeholder="$t('AddQuestions.Choose drill source')"
            style="width:100%"
            filterable
          >
            <el-option
              :label="getProfile.company.name"
              :value="getProfile.company.id"
            ></el-option>
            <el-option label="SATMocks" :value="1"></el-option>
            <el-option label="Official" :value="2"></el-option>
          </el-select> -->
          <el-radio-group v-model="searchTest.company_id" @change="search">
            <el-radio-button :label="null" :value="null">
              SATMocks/Official
            </el-radio-button>
            <el-radio-button
              v-if="getProfile.company"
              :label="getProfile.company.id"
            >
              {{ getProfile.company.name }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="searchTest.type === 'practice' || searchTest.type === 'drill'"
          label="Subject"
        >
          <el-radio-group v-model="searchTest.category" @change="search">
            <!-- <el-radio-button :label="null">
              All
            </el-radio-button> -->
            <el-radio-button :label="searchTest.type === 'practice' ? 13 : 11">
              English
            </el-radio-button>
            <el-radio-button :label="searchTest.type === 'practice' ? 14 : 12">
              Math
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item
          label="Status"
          prop="type"
          v-if="Number(this.searchTest.company_id) > 0"
        >
          <el-radio-group v-model="searchTest.is_draft" @change="search">
            <el-radio-button :label="0">
              Completed
            </el-radio-button>
            <el-radio-button :label="1">
              Draft
            </el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item
          label="Status"
          prop="type"
          v-if="Number(this.searchTest.company_id) > 0"
        >
          <div slot="label">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content" style="max-width: 350px;">
                <ul>
                  <li class="mb-1">
                    <strong>Public</strong>: Display this practice test
                    publicly, so <strong>all</strong> students on the website
                    can see it and do it.
                  </li>
                  <li class="mb-1">
                    <strong>Unlisted</strong>: This practice test is not shown
                    publicly, but anyone with its link can do it (Note: If
                    students without a Pro subscription and are not in your
                    class, they need to spend Coins to do it. If students
                    without a Pro subscription is in your class, they will spend
                    your Coins. If students have a Pro subscription, no Coins
                    will be deducted for doing this test).
                  </li>
                  <li>
                    <strong>Draft</strong>: These are work in progress, so
                    students cannot access it even with its link.
                  </li>
                </ul>
              </div>
              <el-button type="text"
                >Status <i class="fas fa-question-circle"
              /></el-button>
            </el-tooltip>
          </div>
          <el-radio-group v-model="searchTest.status" @change="search">
            <el-radio-button label="PUBLIC">
              Public
            </el-radio-button>
            <el-radio-button label="PRIVATE">
              Unlisted
            </el-radio-button>
            <el-radio-button label="DRAFT">
              Draft
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </template>
      <el-form-item label="Keywords">
        <el-input
          ref="filterInput"
          placeholder="Name"
          v-model="searchTest.search"
          @keyup.enter.native="search"
        >
          <el-button
            el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          >
            Search
          </el-button>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="發佈狀態">
      </el-form-item> -->
    </el-form>
    <template v-if="practices">
      <hr />
      <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          :hide-on-single-page="true"
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <template v-if="practices.length > 0">
        <el-table :data="practices" style="width: 100%" stripe>
          <el-table-column label="Name">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name:
                    searchTest.type === 'composePractices'
                      ? 'SatComposePracticeDetailAll'
                      : 'SatPracticeDetailAll',
                  query: { id: scope.row.id }
                }"
              >
                <b>{{ scope.row.exam.title }}</b>
              </router-link>
            </template>
          </el-table-column>
          <!-- <el-table-column label="Source">
            -
          </el-table-column> -->
          <el-table-column
            label="Subjects"
            width="100"
            v-if="searchTest.type === 'composePractices'"
          >
            <template slot-scope="scope">
              <b class="text-success">
                {{ scope.row.practices.length }}
              </b>
            </template>
          </el-table-column>
          <el-table-column label="Questions" width="100">
            <template slot-scope="scope">
              <template
                class="text-success"
                v-if="searchTest.type === 'composePractices'"
              >
                <b class="text-success">
                  {{ getQuestionsCount(scope.row.practices) }}
                </b>
              </template>
              <template v-else>
                <b class="text-success">{{ scope.row.exam.question_count }}</b>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Score Table"
            width="100"
            v-if="searchTest.type === 'composePractices'"
          >
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.scorable_id && scope.row.scorable_id > 0"
                class="mr-2"
                effect="dark"
                content="View Score Table"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'ScoreTable',
                    params: {
                      type: searchTest.type,
                      practiceId: scope.row.id,
                      id: scope.row.scorable_id
                    }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-table"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <el-tooltip
                v-else
                class="mr-2"
                effect="dark"
                content="No Score Table"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'CreateScoreTable',
                    params: {
                      type: searchTest.type,
                      practiceId: scope.row.id
                    }
                  }"
                >
                  <el-button type="danger" size="mini">
                    <i class="fas fa-exclamation-triangle"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isAdmin || Number(this.searchTest.company_id) > 0"
            label="Students"
            width="100"
          >
            <template slot-scope="scope">
              <b v-if="isAdmin">
                <!-- <i class="fas fa-users"></i> -->
                {{ scope.row.exam.user_exams_count }}
              </b>
              <el-tooltip
                v-else-if="is_mineC(scope.row.exam.company_id)"
                placement="top"
                content="View"
              >
                <router-link
                  :to="{
                    name: 'TeacherViewAllResults',
                    query: {
                      type: searchTest.type,
                      test_title: scope.row.exam.title
                    }
                  }"
                >
                  <el-button type="success" size="mini" style="width: 4rem;">
                    <i class="fas fa-users"></i>
                    {{ scope.row.exam.user_exams_count }}
                  </el-button>
                </router-link>
              </el-tooltip>
              <b v-else>
                <!-- <i class="fas fa-users"></i> -->
                {{ scope.row.exam.user_exams_count }}
              </b>
            </template>
          </el-table-column>
          <el-table-column label="Date Created" width="150">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            width="150"
            label="Displayed on the homepage"
            fixed="right"
          >
            <el-checkbox v-model="checked"></el-checkbox>
          </el-table-column> -->
          <!-- <el-table-column width="250" label="Status">
            <template slot-scope="scope">
              <el-select v-model="scope.row.status">
                <el-option label="Public" value="PUBLIC"> </el-option>
                <el-option label="Private" value="PRIVATE"> </el-option>
                <el-option label="Draft" value="DRAFT"> </el-option>
              </el-select>
            </template>
          </el-table-column> -->
          <el-table-column width="400" label="Actions" fixed="right">
            <template slot-scope="scope">
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Preview"
                placement="top"
              >
                <router-link
                  :to="{
                    name:
                      searchTest.type === 'composePractices'
                        ? 'SatComposePracticeDetailAll'
                        : 'SatPracticeDetailAll',
                    query: { id: scope.row.id }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-eye"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <template v-if="isAdmin || is_mineC(scope.row.exam.company_id)">
                <el-dialog
                  class="class-link"
                  :append-to-body="true"
                  :title="
                    $t('TeacherCourses.Copy and send this link to students')
                  "
                  :visible.sync="showLink"
                  width="50%"
                >
                  <el-alert title="Note" type="warning" :closable="false">
                    <p>
                      You can go to
                      <b class="text-success">Test Results</b>
                      to see test results of your students and those who do your
                      practice tests. If you want to assign this practice test
                      for a particular class, go to the
                      <b class="text-success">Class</b> page, click into that
                      class, and add this practice test to that class.
                    </p>
                  </el-alert>
                  <div class="mt-4 text-center">
                    <div class="referral-code">
                      <a>
                        <span>
                          {{ link }}
                        </span>
                      </a>
                      <el-button class="ml-3" type="success" @click="copyLink">
                        <i class="fas fa-copy"></i>
                        {{ $t("toefl.Copy Link") }}
                      </el-button>
                    </div>
                  </div>
                </el-dialog>
                <el-tooltip effect="dark" content="Test Link" placement="top">
                  <el-button
                    type="warning"
                    size="mini"
                    @click="setLink(scope.row.id, scope.row.exam)"
                  >
                    <i class="fas fa-link"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="mr-2"
                  effect="dark"
                  content="Regrade all (for after you edit your questions)"
                  placement="top"
                >
                  <el-button
                    @click="reGrading(scope.row.exam.id)"
                    type="warning"
                    size="mini"
                  >
                    <i class="fas fa-redo"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  v-if="searchTest.type !== 'composePractices'"
                  class="mr-2"
                  effect="dark"
                  content="Duplicate"
                  placement="top"
                >
                  <el-button
                    class="m-0"
                    @click="copyPractice(searchTest.type, scope.row.id)"
                    type="warning"
                    size="mini"
                  >
                    <i class="fas fa-copy"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="mr-2"
                  effect="dark"
                  content="Edit"
                  placement="top"
                >
                  <router-link
                    :to="{
                      name: getEditLink(searchTest.type),
                      query: { id: scope.row.id, isEdit: 1 }
                    }"
                  >
                    <el-button type="warning" size="mini">
                      <i class="fa fa-edit"></i>
                    </el-button>
                  </router-link>
                </el-tooltip>
                <el-tooltip
                  class="mr-2"
                  effect="dark"
                  content="Change to draft"
                  placement="top"
                  v-if="
                    scope.row.exam.status && scope.row.exam.status !== 'DRAFT'
                  "
                >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="updateStatus(scope.row.exam.id, 'DRAFT')"
                  >
                    <i class="fas fa-level-down-alt"></i>
                  </el-button>
                </el-tooltip>
                <el-popover
                  placement="top"
                  width="250"
                  trigger="hover"
                  v-if="
                    scope.row.exam.status &&
                      scope.row.exam.status === 'DRAFT' &&
                      ((searchTest.type == 'composePractices' &&
                        scope.row.scorable_id &&
                        scope.row.scorable_id > 0) ||
                        searchTest.type !== 'composePractices')
                  "
                >
                  <div class="text-center">
                    <el-button
                      type="success"
                      size="mini"
                      @click="updateStatus(scope.row.exam.id, 'PUBLIC')"
                    >
                      Public
                      <i class="fas fa-users"></i>
                    </el-button>
                    <el-button
                      type="success"
                      size="mini"
                      @click="updateStatus(scope.row.exam.id, 'PRIVATE')"
                    >
                      Unlisted
                      <i class="fas fa-user-lock"></i>
                    </el-button>
                  </div>
                  <!-- <el-button
                    type="success"
                    size="mini"
                    slot="reference"
                    @click="updatePublic(scope.row.id, scope.row.exam.title, 1)"
                  > -->
                  <el-button
                    class="mr-2"
                    type="success"
                    size="mini"
                    slot="reference"
                  >
                    <i class="fas fa-upload"></i>
                  </el-button>
                </el-popover>
                <el-tooltip
                  class="mr-2"
                  effect="dark"
                  content="Remove"
                  placement="top"
                  v-if="
                    isAdmin &&
                      scope.row.exam.status &&
                      scope.row.exam.status === 'DRAFT'
                  "
                >
                  <el-button
                    class="ml-0"
                    type="danger"
                    size="mini"
                    @click="removeExam(scope.row.exam.id)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-table v-else :data="practices" style="width: 100%" stripe>
          <el-table-column label="Name">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name:
                    searchTest.type === 'composePractices'
                      ? 'SatComposePracticeDetailAll'
                      : 'SatPracticeDetailAll',
                  query: { id: scope.row.test_id }
                }"
              >
                <b>{{ scope.row.title }}</b>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="Company"> </el-table-column>
          <el-table-column
            label="Subjects"
            width="100"
            v-if="searchTest.type === 'composePractices'"
          >
            <template slot-scope="scope">
              <b class="text-success">
                {{ scope.row.practices.length }}
              </b>
            </template>
          </el-table-column>
          <el-table-column label="Questions" width="100">
            <template slot-scope="scope">
              <template
                class="text-success"
                v-if="searchTest.type === 'composePractices'"
              >
                <b class="text-success">
                  {{ getQuestionsCount(scope.row.practices) }}
                </b>
              </template>
              <template v-else>
                <b class="text-success">{{ scope.row.exam_questions_count }}</b>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Score Table"
            width="100"
            v-if="
              searchTest.type === 'composePractices' && (!isTeacher || is_mine)
            "
          >
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.scorable_id && scope.row.scorable_id > 0"
                class="mr-2"
                effect="dark"
                content="View Score Table"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'ScoreTable',
                    params: {
                      type: searchTest.type,
                      practiceId: scope.row.id,
                      id: scope.row.scorable_id
                    }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-table"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <el-tooltip
                v-else
                class="mr-2"
                effect="dark"
                content="No Score Table"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'CreateScoreTable',
                    params: {
                      type: searchTest.type,
                      practiceId: scope.row.id
                    }
                  }"
                >
                  <el-button type="danger" size="mini">
                    <i class="fas fa-exclamation-triangle"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Date Created" width="150">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column width="350" label="Actions" fixed="right">
            <template slot-scope="scope">
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Preview"
                placement="top"
              >
                <router-link
                  :to="{
                    name:
                      searchTest.type === 'composePractices'
                        ? 'SatComposePracticeDetailAll'
                        : 'SatPracticeDetailAll',
                    query: { id: scope.row.test_id }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-eye"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <template>
                <el-tooltip
                  class="mr-2"
                  effect="dark"
                  content="Regrade all (for after you edit your questions)"
                  placement="top"
                >
                  <el-button
                    @click="reGrading(scope.row.exam.id)"
                    type="warning"
                    size="mini"
                  >
                    <i class="fas fa-redo"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  v-if="searchTest.type !== 'composePractices'"
                  class="mr-2"
                  effect="dark"
                  content="Duplicate"
                  placement="top"
                >
                  <el-button
                    class="m-0"
                    @click="copyPractice(searchTest.type, scope.row.test_id)"
                    type="warning"
                    size="mini"
                  >
                    <i class="fas fa-copy"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="mr-2"
                  effect="dark"
                  content="Edit"
                  placement="top"
                >
                  <router-link
                    :to="{
                      name:
                        searchTest.type === 'composePractices'
                          ? 'SatComposePracticeDetail'
                          : 'SatPracticeDetail',
                      query: { id: scope.row.test_id, isEdit: 1 }
                    }"
                  >
                    <el-button type="warning" size="mini">
                      <i class="fa fa-edit"></i>
                    </el-button>
                  </router-link>
                </el-tooltip>
                <el-tooltip
                  class="mr-2"
                  effect="dark"
                  content="Change to Private"
                  placement="top"
                  v-if="scope.row.is_public && scope.row.is_public === 1"
                >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="updatePublic(scope.row.id, scope.row.exam.title, 0)"
                  >
                    <i class="fas fa-level-down-alt"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="Publish"
                  placement="top"
                  v-if="
                    scope.row.is_public > -1 &&
                      scope.row.is_public === 0 &&
                      ((searchTest.type == 'composePractices' &&
                        scope.row.scorable_id &&
                        scope.row.scorable_id > 0) ||
                        searchTest.type !== 'composePractices')
                  "
                >
                  <el-button
                    type="success"
                    size="mini"
                    @click="updatePublic(scope.row.id, scope.row.exam.title, 1)"
                  >
                    <i class="fas fa-upload"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>
        </el-table> -->
      </template>
      <div v-else>
        <el-empty :description="$t('courses.empty-text')"></el-empty>
      </div>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          :hide-on-single-page="true"
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import Company from "@/apis/company";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  metaInfo() {
    return {
      title: "SAT Practices - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      searchTest: {
        isNew: null,
        type: null,
        // subject: null,
        category: null,
        // is_public: null,
        status: "draft",
        search: null,
        company_id: null,
        source: 3
      },
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      subjects: [],
      companies: [],
      showLink: false
      // composePractices: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    ...mapState("user", ["profile"]),
    CreateLink() {
      let Link = "";
      if (this.searchTest.type === "drill") {
        if (this.isAdmin) {
          Link = {
            name: "SatAddPractice",
            query: { type: "drill" }
          };
        }
        if (this.isTeacher) {
          Link = {
            name: "TeacherSatAddPractice",
            query: { type: "drill" }
          };
        }
      } else if (this.searchTest.type === "practice") {
        if (this.isAdmin) {
          Link = {
            name: "SatAddPractice"
          };
        }
        if (this.isTeacher) {
          Link = {
            name: "TeacherSatAddPractice"
          };
        }
        // Link = {
        //   name: "SatAddPractice"
        // };
      } else if (this.searchTest.type === "adaptive") {
        Link = {
          name: "SatAddAdaptive"
        };
      } else if (this.searchTest.type === "composePractices") {
        Link = {
          name: "SatAddComposePractice"
        };
      }
      return Link;
    },
    myTitle() {
      let myTitle = "";
      if (this.searchTest.type === "drill") {
        myTitle = this.$t("sat.Drill Practice");
      } else if (this.searchTest.type === "adaptive") {
        myTitle = this.$t("Adaptive Tests");
      } else if (this.searchTest.type === "practice") {
        myTitle = this.$t("sat.Partial Tests");
      } else if (this.searchTest.type === "composePractices") {
        myTitle = this.$t("sat.Full Tests");
      }
      return myTitle;
    },
    instant() {
      return instant;
    },
    isTeacher() {
      return this.$route.name === "TeacherSatManagement";
    },
    isCompany() {
      return this.$route.name === "CompanySatManagement";
    },
    isAdmin() {
      return this.$route.name === "SatManagement";
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    subjectsNow() {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      let subjects = [];
      if (this.$route.query.type == "drill") {
        if (this.searchTest.isNew === 1) {
          subjects = [
            { id: 11, name: "english" },
            { id: 12, name: "math" }
          ];
        } else {
          subjects = [
            { id: 5, name: "reading" },
            { id: 6, name: "writing" },
            { id: 7, name: "math" },
            { id: 8, name: "math calculator" }
          ];
        }
      } else {
        if (this.searchTest.isNew === 1) {
          subjects = [
            { id: 13, name: "english" },
            { id: 14, name: "math" }
          ];
        } else {
          subjects = [
            { id: 1, name: "reading" },
            { id: 2, name: "writing" },
            { id: 3, name: "math" },
            { id: 4, name: "math calculator" }
          ];
        }
      }
      return subjects;
    },
    isNew() {
      let isNew = 1;
      if (this.$route.query.isNew === undefined) {
        isNew = 1;
      } else {
        isNew = parseInt(this.$route.query.isNew);
      }
      return isNew;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    company_id() {
      let company_id = null;
      if (this.$route.query.company_id) {
        company_id = this.$route.query.company_id;
      }
      return company_id;
    },
    subjectId() {
      let subjectId = null;
      if (this.$route.query.subject_id) {
        subjectId = this.$route.query.subject_id;
      }
      return subjectId;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {
    // "searchTest.isNew": {
    //   handler: function() {
    //     this.searchTest.category = this.subjectsNow[0].id;
    //   }
    // }
  },

  async mounted() {
    this.searchTest.status = this.$route.query.status
      ? this.$route.query.status
      : "DRAFT";
    this.searchTest.isNew =
      Number(this.$route.query.isNew) > -1
        ? Number(this.$route.query.isNew)
        : 1;

    this.searchTest.category = this.$route.query.category
      ? this.$route.query.category
      : this.subjectsNow[0].id;

    this.searchTest.search = this.$route.query.search
      ? this.$route.query.search
      : null;
    this.searchTest.source =
      Number(this.$route.query.source) > -1
        ? Number(this.$route.query.source)
        : 3;
    this.searchTest.company_id =
      Number(this.$route.query.company_id) > 0
        ? Number(this.$route.query.company_id)
        : null;
    if (!this.searchTest.company_id && !this.isAdmin) {
      this.searchTest.status = "PUBLIC";
    }
    this.searchTest.type = this.$route.query.type
      ? this.$route.query.type
      : "composePractices";
    if (this.isLogin) {
      this.getData();
    } else {
      if (this.theme === "SAT") {
        this.$router.push({ name: "Login" });
      }
    }
    this.getCompanies();
  },

  methods: {
    removeExam(examId) {
      this.$confirm("Are you sure you want to remove this test?", "Remove", {
        confirmButtonText: this.$t("alert.confirm"),
        cancelButtonText: this.$t("alert.cancel"),
        type: "warning"
      })
        .then(async () => {
          await SAT.removeExam(examId);
          this.$message({
            message: "Success!",
            type: "success"
          });
          this.getData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    setLink(id, exam) {
      let practiceType = "composePractices";
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;

      if (exam.category === 9 || exam.category === 15) {
        practiceType = "composePractices";
      } else if (exam.category === 10 || exam.category === 16) {
        practiceType = "adaptivePractices";
      } else {
        practiceType = "singlePractice";
      }
      this.link = `${baseDomainPath}test/${practiceType}/${id}/start`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    },
    getEditLink(type) {
      if (this.isAdmin) {
        return type === "composePractices"
          ? "SatComposePracticeDetail"
          : "SatPracticeDetail";
      } else {
        return type === "composePractices"
          ? "TeacherSatComposePracticeDetail"
          : "TeacherSatPracticeDetail";
      }
    },
    is_mineC(company_id) {
      if (company_id && this.getProfile.company) {
        return this.getProfile.company.id === company_id;
      } else {
        return false;
      }
    },
    async getCompanies() {
      if (this.isAdmin) {
        const res = await Company.listCompany({ per_page: 100 });
        this.companies = res.companies.data;
      } else if (this.isTeacher) {
        const res = await Company.listCompany({ per_page: 100 });
        this.companies = res.companies.data;
      } else {
        const res = await Company.listCompany({ per_page: 100 });
        this.companies = res.companies.data;
      }
    },
    reGrading(examId) {
      this.$confirm(
        this.$t("management.Are you sure you want to regrade all tests?"),
        this.$t("management.Regrade"),
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(async () => {
          await SAT.reGrading(examId);
          this.$message({
            message: "Success!",
            type: "success"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    async copyPractice(type, id) {
      if (type === "composePractices") {
        await SAT.copyComposePractice(id);
      } else {
        await SAT.copyPractice(id);
      }
      await this.getData();
      this.$message({
        message: this.$t("management.Duplicate created"),
        type: "success"
      });
    },
    getQuestionsCount(practices) {
      let count = 0;
      practices.forEach(practice => {
        count += practice.exam.exam_questions_count;
      });
      return count;
    },
    async getData() {
      let res = null;
      let edit_user_id = null;
      if (this.searchTest.type === "composePractices") {
        res = await SAT.getComposePractices({
          // edit_user_id,
          is_new: this.searchTest.isNew,
          page: this.page,
          per_page: 10,
          is_public: this.searchTest.status === "PUBLIC" ? 1 : 0,
          keyword: this.searchTest.search,
          company_id: this.searchTest.company_id,
          status: this.searchTest.status
          // category: this.searchTest.category
        });
        this.practices = res.compose_practice.data;
        this.resultsTotal = res.compose_practice.total;
        this.pageSize = res.compose_practice.per_page;
      } else if (
        this.searchTest.type === "practice" ||
        this.searchTest.type === "drill"
      ) {
        // if (this.searchTest.source === 4) {
        res = await SAT.getPractices({
          page: this.page,
          per_page: 10,
          status: this.searchTest.status,
          keyword: this.searchTest.search,
          company_id: this.searchTest.company_id,
          category: this.searchTest.category
        });
        this.practices = res.practices.data;
        this.resultsTotal = res.practices.total;
        this.pageSize = res.practices.per_page;
      }
    },
    async updatePublic(id, title, is_public) {
      if (
        this.searchTest.type === "practice" ||
        this.searchTest.type === "drill"
      ) {
        await SAT.updatePractice(id, {
          practice: {
            exam: {
              title: title,
              is_public: is_public
            }
          }
        });
      } else if (this.searchTest.type === "composePractices") {
        await SAT.updateComposePractice(id, {
          compose_practice: {
            exam: {
              title: title,
              is_public: is_public
            }
          }
        });
      }
      if (is_public === 1) {
        await this.$message({
          message: this.$t("management.Published!"),
          type: "success"
        });
      } else {
        await this.$message(this.$t("management.Moved to Drafts"));
      }
      this.getData();
    },
    async updateStatus(id, status) {
      await SAT.updateStatus(id, {
        status: status
      });
      await this.$message({
        message: this.$t("management.Published!"),
        type: "success"
      });
      this.getData();
    },
    async search(type) {
      if (type === "changeNew") {
        this.searchTest.subject = null;
      }
      this.$router.replace({
        params: {
          ...this.$route.params
        },
        query: {
          ...this.$route.query,
          ...this.searchTest,
          page: 1
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>
